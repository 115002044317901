/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactElement, useEffect, useState } from "react"
import { Layout } from "antd"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Cookie from "../components/Cookie/Cookie"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"

import "../assets/css/style.scss"
import "../assets/css/app.less" // for importing antd
import FindaHotel from "../components/FindaHotel/FindaHotel"

const { Content } = Layout

const client = new ApolloClient({
  uri: process.env.GATSBY_API_URL,
  cache: new InMemoryCache(),
})

type LayoutPropTypes = {
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  pageContext?: any
  title: string
  isBookingLayout?: boolean
}

const layoutLanding = ({
  children,
  pageContext,
  title,
  isBookingLayout,
}: LayoutPropTypes): ReactElement => {
  const { brands, hotels, footer } = pageContext || {
    brands: [],
    hotels: [],
    footer: [],
  }
  const [showSideButton, setShowSideButton] = useState(true)

  useEffect(() => {
    if (typeof window !== undefined) {
      if (
        window.location.href.indexOf("booking-confirmation") > 0 ||
        window.location.href.indexOf("booking-details") > 0
      ) {
        setShowSideButton(false)
      } else {
        setShowSideButton(true)
      }
    }
  }, [])

  const findHotelOpen = () => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById("findhotelFullscreen")

      if (ele) document.body.style.overflow = "hidden"
      return ele?.classList.add("active")
    }
  }

  const bookNowSideButton = showSideButton ? (
    <span onClick={findHotelOpen} className="sticky-btn" id="stickyBtn">
      Book Now
    </span>
  ) : null

  return (
    <ApolloProvider client={client}>
      <Helmet>
        {<script type="module" src="/firebase.js"></script>}
        {
          <noscript>
            {`
                <img
                height="1"
                width="1"
                src="https://www.facebook.com/tr?id=860210341343925&ev=PageView
            &noscript=1"
              />`}
          </noscript>
        }
        {
          <script src="https://www.googletagmanager.com/gtag/js?id=UA-56517066-2"></script>
        }
      </Helmet>
      <Layout className={`layout ${title}`}>
        <Header
          searchData={brands}
          siteTitle={`Title`}
          isBookingHeader={isBookingLayout}
        />
        <FindaHotel
          data={{
            tarsiergraphql: {
              getBrands: brands,
              getHotels: hotels,
            },
          }}
          dropdownClassName="certain-category-search-dropdown"
          placeholder="Search Hotel and Location"
          containerId="findhotelFullscreen"
          formID="formHotel2"
          bookingFormStatus={true}
        />
        {bookNowSideButton}
        <Content style={{ marginTop: isBookingLayout ? 72 : "unset" }}>
          {children}
        </Content>
        <Footer
          isBookingFooter={isBookingLayout}
          data={{
            tarsiergraphql: {
              getFooter: footer,
            },
          }}
        />
        <Cookie />
      </Layout>
    </ApolloProvider>
  )
}

export default layoutLanding
