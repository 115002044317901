import React from 'react'
import { Row, Col, Button } from "antd"
import moment from "moment"

import { EditPenIcon, KeyCircleIcon, CalendarIcon } from "../../utilities/icons/icons"

const CheckInOutDetails = ({
    dateFormatBooking,
    datePickerHotel,
    rooms,
    addAllPersons,
    editBooking
}) => {
    return (
        <Row
          justify="space-between"
          align="middle"
          className="checkout-details"
        >
          <Col span={20}>
            <Row gutter={35}>
              <Col>
                <div className="checkout-details-title">
                  Check-In and Check-Out Dates
                </div>
                <div className="checkout-details-value">
                <CalendarIcon className="calendar-icn-mobile"/>
                  {moment(datePickerHotel?.[0])?.format(
                    dateFormatBooking
                  )}{" "}
                  -{" "}
                  {moment(datePickerHotel?.[1])?.format(
                    dateFormatBooking
                  )}
                </div>
              </Col>
              <Col>
                <div className="checkout-details-title">
                  Rooms & Guests
                </div>
                <div className="checkout-details-value">
                <KeyCircleIcon className="key-icn-mobile"/>
                  {`${rooms.length} ${
                    rooms.length > 1 ? `Rooms` : `Room`
                  }: ${addAllPersons(rooms, "adult")} ${
                    addAllPersons(rooms, "adult") > 1
                      ? `Adults`
                      : `Adult`
                  }, ${addAllPersons(rooms, "children")} ${
                    addAllPersons(rooms, "children") > 1
                      ? `Children`
                      : `Child`
                  }`}
        
                     
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button
              className="edit-btn edit-btn-hide"
              type="default"
              onClick={editBooking}
            >
              <EditPenIcon />
              EDIT
            </Button>
            <EditPenIcon className="edit-btn-mobile" onClick={editBooking} />

          </Col>
        </Row>
    )
}

export default CheckInOutDetails
