import React from "react"

const ButtonLink = ({ link, action, className, title }) => {
  return (
    <a
      style={action ? { opacity: 1 } : { opacity: 0, display: "none" }}
      href={link}
      target="_blank"
      className={className}
    >
      {title}
    </a>
  )
}

export default ButtonLink
