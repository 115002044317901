import React, {useState} from 'react'
import { Row, Col, Button } from 'antd'

const Cookie = () => {
        const [close, setClose] = useState(() => {
            if (typeof window !== 'undefined') {
                const localData = localStorage.getItem('rhrCookies');
                return localData ? false : true;
            }
        });
    

    const cookies = () => {
        setClose(false);
        if (typeof window !== 'undefined') {
            localStorage.setItem('rhrCookies', 'false');
        }
    }

    return (
        <> 
            {close && (
                <Row className="cookie-container" style={close ? {} : {opacity: 0, position: "absolute", zIndex: -1}}>
                    <Col xl={16} className="cookie-details">
                        <p><strong>Cookie Policy</strong></p>
                        <p>This website makes use of cookies to improve your browsing experience. By clicking this button, you accept the use of cookies on this website. Learn more about our <a href="/data-privacy-and-cookies-policy" target="_blank">Data Privacy and Cookies Policy here</a></p>
                    </Col>
                    <Col>
                    <Button className="rhr-btn decline" onClick={() =>setClose(false)}>Decline</Button>
                    <Button type="primary" className="rhr-btn" onClick={cookies}>Accept All Cookies</Button>
                    </Col>
                 
                </Row>
            )}
        </>
    )
}

export default Cookie
