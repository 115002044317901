import { BrandCategoryI, RoomsI } from "./interface";

 export const brandBategories: BrandCategoryI[] = [
    {
        id: 1,
        category: "ULTRA LUXURY"
    },
    {
        id: 2,
        category: "LUXURY"
    },
    {
        id: 3,
        category: "DELUXE"
    },
    {
        id: 4,
        category: "UPSCALE"
    },
    {
        id: 5,
        category: "MIDSCALE"
    },
    {
        id: 6,
        category: "ESSENTIAL VALUE"
    },
    {
        id: 7,
        category: "DORMITORIES"
    }
];

export const roomsCount: RoomsI[] = [
    {
        label: "Room",
        adult: 1,
        children: 0
    }
];

export const hotelDate = [
    '2018-01-11T12:32:26.551Z',
    '2018-01-11T12:32:26.551Z'
]

export const dateFormat = 'MMM DD, YYYY';

export const dateFormatBooking = 'ddd, MMM DD YYYY';

export const dateFormatApi = 'YYYY-MM-DD';