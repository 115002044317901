import React from 'react'
import './ErrorMessage.scss'

const ErrorMessage = ({text}) => {
    return (
        <div className='error-container'>
            <span className='error-container-text'>
                {text}
            </span>
        </div>
    )
}

export default ErrorMessage