import React, { ReactChild } from "react"
import { Modal, Col, Row } from "antd"
import {
  StyledModal,
  ContentWrapper,
  ImageWrapper,
  Title,
  Message,
  ErrorCircleIcon,
} from "./styled"

import CheckIcon from "../../assets/images/ic_check_circle.svg"
import WarningIcon from "../../assets/images/ic_report.svg"

interface Props {
  visible: boolean
  message?: string | ReactChild
  onOk?: () => void
  onCancel?: () => void
  titleMessage?: string
  noCancelBtn?: boolean
  noOKBtn?: boolean
  isErrorIcon?: boolean
  isWarningIcon?: boolean
  isSuccessIcon?: boolean
  okText?: string
  cancelText?: string
  closable?: boolean
  maskClosable?: boolean
  okButtonWidth?: string | number
  cancelButtonWidth?: string | number
}

const CustomModal: React.FC<Props> = ({
  visible,
  message,
  onOk,
  onCancel,
  titleMessage,
  noCancelBtn,
  noOKBtn,
  isErrorIcon,
  isWarningIcon,
  isSuccessIcon,
  okText,
  cancelText,
  closable,
  maskClosable,
  okButtonWidth,
  cancelButtonWidth,
}) => {
  return (
    <StyledModal
      centered
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={{
        size: "large",
        style: {
          width: cancelButtonWidth ? cancelButtonWidth : "6em",
          display: noCancelBtn ? "none" : "",
          textTransform: "uppercase",
        },
      }}
      okButtonProps={{
        size: "large",
        style: {
          width: okButtonWidth ? okButtonWidth : "6em",
          marginLeft: noCancelBtn ? 0 : "",
          display: noOKBtn ? "none" : "",
          textTransform: "uppercase",
        },
      }}
      width={"26em"}
      cancelText={cancelText}
      okText={okText}
      closable={closable}
      maskClosable={maskClosable}
    >
      <Row justify="center">
        <Col>
          <ContentWrapper>
            <ImageWrapper>
              {isErrorIcon && <ErrorCircleIcon />}
              {isWarningIcon && <img src={WarningIcon} alt="" />}
              {isSuccessIcon && <img src={CheckIcon} alt="" />}
            </ImageWrapper>
            {titleMessage && <Title>{titleMessage}</Title>}
            <Message>{message}</Message>
          </ContentWrapper>
        </Col>
      </Row>
    </StyledModal>
  )
}

export default CustomModal
